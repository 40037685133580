button.ant-btn.ant-btn-primary.menu-button {
    padding: unset !important;
    margin: unset !important;
    border: unset !important;
    background: #2f4f4f  !important;
    width: max-content !important;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
    width: 100%;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block !important;
    margin-right: 4px !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    font-family: SimSun, sans-serif;
    line-height: 1 !important;
    content: '*' !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
    content: '' !important;
}

.ant-form-item-label > label::after {
    display: none !important;
    content: '' !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #2d6282 !important;
}

th.ant-table-cell {
    font-size: 11px;
    font-weight: 600 !important;
}
td.ant-table-cell {
    font-size: 11px;
    font-weight: 500 !important;
}

span.ant-input-group-addon {
    background: #2d5b75;
    color: #fff;
    padding: 0px 10px;
}

.ant-input-group-wrapper {
    margin: 15px 0px;
}

.ant-input-lg {
    font-size: 12px !important;
}

.ant-table-body {
    overflow-y: hidden;
}

.ant-table-body::-webkit-scrollbar {
    height: 8px;
  }

  .ant-table-body::-webkit-scrollbar:vertical {
    display: none !important;
  }
   
.ant-table-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
   
.ant-table-body::-webkit-scrollbar-thumb {
    background-color: #2f4f4f;
    outline: 1px solid #2f4f4f;
}

th.ant-table-cell {
    background: #2f4f4f !important;
    color: #fff !important;
}

.ant-input-affix-wrapper-lg {
    padding: 0.5px 11px !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    border-bottom: 1px solid #2f4f4f;
}
.ant-table-tbody > tr.ant-table-row > td {
    border-bottom: 1px solid #2f4f4f;
}

.ant-input[disabled], .ant-input-prefix {
    color: rgb(4 4 4 / 55%) !important;
}

.ant-input-affix-wrapper-disabled {
    background-color: #fafafa !important;
}

.ant-checkbox-group {
    display: flex !important;
    justify-content: space-around !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0px 45px !important;
}

.ant-menu-horizontal {
    display: flex !important;
    justify-content: center !important;
    border: unset !important;
}

  p.form-title {
    margin-bottom: 30px;
  }
   p.form-title {
    color: #333333;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
  }

  .ant-drawer-close {
      padding: 5px !important;
      top: 10px !important;
      right: 10px !important;
  }

  .ant-card-extra {
    padding: 5px 0px !important;
    font-size: 24px !important;
}

.ant-card-head-title {
    padding: 5px 0px !important;
}
